import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
    message?: string
}

function SleepPage(props: Props) {
    useEffect(() => {
        document.body.className = 'page-sleep';
    }, [])


    const { t } = useTranslation();

    return (
        <main className="container-fluid min-vh-100 min-vw-100">
            {/*Mobile*/}
            <div className="d-block d-sm-none text-center pt-5">

                <h4 className="fw-bold text-light text-shadow">Selleks korraks kõik :) </h4>
                <br/><br/>
                <p className="mt-2 fw-bold text-light text-shadow">Kohtumiseni järgmisel hooajal!</p>
{/*
                <p>
                    <a href="https://veeteed.meola.eu">veeteed.meola.eu</a>
                </p>
*/}

            </div>

            {/*Desktop*/}
            <div className="d-none d-sm-block text-center pt-5 px-5 sleep-desktop">
                <h1 className="fw-bold text-light text-shadow"> </h1>

                <p className="fw-bold text-light text-shadow">Selleks korraks kõik :)</p>

                <p><small className="fs-6 text-light text-shadow">Kohtumiseni järgmisel hooajal!</small></p>

                {/*<p>
                    <a href="https://veeteed.meola.eu">veeteed.meola.eu</a>
                </p>*/}

            </div>


        </main>
    );
}

export default SleepPage;
